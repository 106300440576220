import type { NextPage } from 'next';
import useUser from '../auth/useUser';
import CompanySelector from 'ui/components/companySelector/company-selector';

const Home: NextPage = () => {
  const currentUser = useUser();

  return (
    <CompanySelector
      user={currentUser}
      options={{
        gascaribe: {
          pathPrefix: 'gdc',
          link: '/projects',
          role: 'access_gdc',
        },
        gasguajira: {
          pathPrefix: 'gdg',
          link: '/projects',
          role: 'access_gdg',
        },
        efigas: {
          pathPrefix: 'efg',
          link: '/projects',
          role: 'access_efg',
        },
      }}
    />
  );
};

export default Home;
