import type { NextPage } from 'next';
import Grid from '@mui/material/Grid';
import { Policy } from '../auth/Policy';
import { Typography } from '@mui/material';
import CompanyButton from './CompanyButton';

import logoEfigas from '../../public/images/Logo_efigas_full.svg';
import logoGascaribe from '../../public/images/Logo_Gases_Two_lines.svg';
import logoGuajira from '../../public/images/Logo_gases_guajira_full.svg';

const defaultRole = 'access_company';
const defaultPathPrefix = 'gdc';

enum CompanyEnum {
  gascaribe = 'gascaribe',
  efigas = 'efigas',
  gasguajira = 'gasguajira',
}

interface User {
  name: string;
  roles: string[];
}

const companyLogos: Record<CompanyEnum, string> = {
  [CompanyEnum.gascaribe]: logoGascaribe,
  [CompanyEnum.gasguajira]: logoGuajira,
  [CompanyEnum.efigas]: logoEfigas,
};

interface CompanyOption {
  pathPrefix: string;
  link: string;
  role: string;
}

interface CompanySelectorProps {
  user: User | null;
  options: Record<CompanyEnum, CompanyOption>;
}

const CompanySelectorComponent: NextPage<CompanySelectorProps> = ({
  user,
  options,
}) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      marginTop={5}
      spacing={3}
    >
      <Grid item xs={12}>
        <Typography fontSize={30} align="center" fontWeight="medium">
          Seleccione su gasera:
        </Typography>
      </Grid>
      {Object.entries(options).map(([key, { pathPrefix, link, role }]) => (
        <Policy user={user} roles={[role || defaultRole]} key={key}>
          <Grid item>
            <CompanyButton
              link={`${pathPrefix || defaultPathPrefix}${link}`}
              src={companyLogos[key as CompanyEnum]}
              alt={`${companyLogos[key as CompanyEnum]}`}
            />
          </Grid>
        </Policy>
      ))}
    </Grid>
  );
};

export default CompanySelectorComponent;
