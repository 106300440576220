import { Paper } from '@mui/material';
import Image from 'next/image';
import { FC, useCallback } from 'react';
import { useRouter } from 'next/navigation';

interface ICompanyButtonProps {
  link: string;
  src: string;
  alt: string;
}

const CompanyButton: FC<ICompanyButtonProps> = ({ link, src, alt }) => {
  const router = useRouter();

  const onClick = useCallback(() => router.push(`${link}`), [link, router]);

  return (
    <Paper
      sx={{
        height: 150,
        width: 300,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        transition: 'box-shadow 0.3s',
        '&:hover': {
          boxShadow: 4,
        },
      }}
      onClick={onClick}
    >
      <Image src={src} alt={alt} height={105} />
    </Paper>
  );
};

export default CompanyButton;
